<template>
  <div class="main-manage-container">
    <panel-manage panelTitle="历史升级包" isShowGoBackBtn>
      <div class="up-tool-box2">
        <div class="tool-btn">
          <auth-button @handleClick="jumpAction">上传新版本</auth-button>
        </div>
      </div>

      <div class="table-action-container">
        <filter-box
          @reset="resetCall"
          @submit="filterCall"
          ref="filterBoxRef"
        ></filter-box>

        <table-box ref="tableBoxRef"></table-box>
      </div>
    </panel-manage>
  </div>
</template>
success
<script setup>
import filterBox from "./components/filter.vue";
import tableBox from "./components/table.vue";

import {
  onMounted,
  ref,
  getCurrentInstance,
  computed,
  reactive,
  nextTick,
} from "vue";
import { usePageModule } from "@/utils/mixin";
const { routeParams } = usePageModule();

// 筛选表格组件
// ref
const filterBoxRef = ref();

/* 表格组件操作 */
const tableBoxRef = ref();
const filterCall = (params) => {
  tableBoxRef.value.fetchFilterData(params);
  openTableLoading();
};
const resetCall = (params) => {
  tableBoxRef.value.resetParams(params);
  openTableLoading();
};

// 表格加载
const openTableLoading = () => {
  tableBoxRef.value.openTableLoading();
};

// 打开上传版本弹窗
const jumpAction = () => {
  tableBoxRef.value.openVersionDialog();
};

// 初始
const init = () => {
  const { deviceId } = routeParams;
  // 赋值 设备型号ID
  filterBoxRef.value.setFilterParams({
    deviceId,
  });
};

onMounted(async () => {
  init();
});
</script>

<style lang="scss" scoped>
@import "@/assets/css/main_page.scss";
</style>

<template>
  <div class="filter-box">
    <!-- 表单 -->
    <div class="filter-form">
      <el-form :inline="true" labelWidth="85px">
        <el-form-item label="版本号：" label-width="70px">
          <ml-input
            placeholder="请输入版本号"
            v-model="formParams.versionCode"
            clearable
          ></ml-input>
        </el-form-item>
        <el-form-item label="版本类型：" label-width="90px">
          <ml-select
            :options="versionTypes"
            v-model="formParams.upgradeType"
            placeholder="请选择版本类型"
            clearable
          >
          </ml-select>
        </el-form-item>

        <el-form-item>
          <ml-button type="" @handleClick="submit">搜索</ml-button>
          <ml-button type="primary" @handleClick="reset"> 重置 </ml-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { versionOpts } from "@/utils/options";

const emits = defineEmits(["submit", "reset"]);

/* 选项信息 */
const versionTypes = versionOpts();

// 默认数据
const defaultParams = () => ({
  versionCode: "",
  upgradeType: "",
});
const formParams = reactive(defaultParams());

const submit = () => {
  emits("submit", formParams);
};

const reset = () => {
  const defaultData = defaultParams();
  for (let i in defaultData) {
    formParams[i] = defaultData[i];
  }
  emits("reset", defaultData);
};

/* 设置筛选参数并查询 */
const setFilterParams = (params) => {
  Object.assign(formParams, params);

  setTimeout(() => {
    submit();
  });
};

// 获取筛选参数
const getFilterParams = () => formParams;

defineExpose({
  setFilterParams,
  getFilterParams,
  submit,
});
</script>

<style lang="scss" scoped>
.filter-form {
  user-select: none;
}
</style>
